import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScrollToTop from "./utils/ScrollToTop";
import SEO from "./utils/SEO";

import AuthProvider from "./auth/auth-provider";
import AuthRouteGuard from "./auth/auth-route-guard";

import AuthHeader from "./blocks/Header/AuthHeader";
import Footer from "./blocks/Footer/Footer";

import "./App.scss";

// components
const Home = lazy(() => import("./pages/Home"));
const Blog = lazy(() => import("./pages/Blog"));
const Post = lazy(() => import("./pages/Post"));
const Catalog = lazy(() => import("./pages/Catalog"));
const CatalogClinic = lazy(() => import("./pages/CatalogClinic"));
const Doctor = lazy(() => import("./pages/Doctor"));
const Clinic = lazy(() => import("./pages/Clinic"));
const Checkup = lazy(() => import("./pages/Checkup"));
const RegDoc = lazy(() => import("./pages/RegDoc"));
const RegClinic = lazy(() => import("./pages/RegClinic"));
const Auth = lazy(() => import("./pages/Auth"));
const AuthSellers = lazy(() => import("./pages/AuthSellers"));
const ForgetPassword = lazy(() => import("./pages/ForgetPassword"));
const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const ClinicCheckup = lazy(() => import("./pages/ClinicCheckup"));
const EditCheckup = lazy(() => import("./pages/EditCheckup"));
const Politics = lazy(() => import("./pages/Politics"));
const Agreement = lazy(() => import("./pages/Agreement"));
const RoomNavigate = lazy(() => import("./utils/navigate/RoomNavigate"));
const WriteReview = lazy(() => import("./pages/WriteReview"));
const WriteRecommendation = lazy(() => import("./pages/WriteRecommendation"));
const Meeting = lazy(() => import("./pages/Meeting"));
const NoMatch = lazy(() => import("./pages/NoMatch"));
const ContinueBooking = lazy(() => import("./pages/ContinueBooking"));
const LKEditNavigate = lazy(() => import("./utils/navigate/LKEditNavigate"));
const LKNavigate = lazy(() => import("./utils/navigate/LKNavigate"));
const LKEditPromoNavigate = lazy(() =>
  import("./utils/navigate/LKEditPromoNavigate")
);
const LKPromoNavigate = lazy(() => import("./utils/navigate/LKPromoNavigate"));

const FarmBanners = lazy(() => import("./pages/FarmBanners"));
const FarmArticles = lazy(() => import("./pages/FarmArticles"));
const FarmProducts = lazy(() => import("./pages/FarmProducts"));
const EditBanners = lazy(() => import("./pages/EditBanners"));
const EditArticles = lazy(() => import("./pages/EditArticles"));
const EditProducts = lazy(() => import("./pages/EditProducts"));
const Subcategory = lazy(() => import("./pages/Subcategory"));
const Article = lazy(() => import("./pages/Article"));
const Product = lazy(() => import("./pages/Product"));

let App = () => {
  return (
    <Router>
      <div className="App">
        <AuthProvider>
          <SEO
            pageProps={{
              title: "Revomed.ru - телемедицинский сервис для женщин",
            }}
          />
          <Suspense fallback={<div className="main-loader"></div>}>
            <AuthHeader />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/blog" element={<Blog />} />

              <Route exact path="/post/:postId" element={<Post />} />

              <Route exact path="/meeting" element={<Meeting />} />

              <Route exact path="/catalog" element={<Catalog />} />
              <Route exact path="/doctor/:docId" element={<Doctor />} />

              <Route exact path="/clinics" element={<CatalogClinic />} />
              <Route exact path="/clinic/:clinicId" element={<Clinic />} />
              <Route exact path="/checkup/:checkupId" element={<Checkup />} />

              <Route exact path="/reg/doc/:clinicLink" element={<RegDoc />} />
              <Route exact path="/reg/doc" element={<RegDoc />} />
              <Route exact path="/reg/clinic" element={<RegClinic />} />
              <Route exact path="/auth" element={<Auth />} />
              <Route exact path="/auth/sellers" element={<AuthSellers />} />
              <Route
                exact
                path="/password/forget"
                element={<ForgetPassword />}
              />
              <Route
                exact
                path="/password/recovery/:link"
                element={<ChangePassword />}
              />

              <Route
                exact
                path="/continue-booking"
                element={<ContinueBooking />}
              />
              <Route element={<AuthRouteGuard />}>
                <Route path="/lk/edit" element={<LKEditNavigate />} />
                <Route path="/lk" element={<LKNavigate />} />
                <Route
                  path="/lk/checkup/edit/:checkupId"
                  element={<EditCheckup />}
                />
                <Route path="/lk/checkup/edit" element={<EditCheckup />} />
                <Route path="/lk/checkup" element={<ClinicCheckup />} />

                <Route
                  path="/lk/promo/edit/:promoId"
                  element={<LKEditPromoNavigate />}
                />
                <Route
                  path="/lk/promo/edit"
                  element={<LKEditPromoNavigate />}
                />

                <Route path="/lk/promo" element={<LKPromoNavigate />} />

                <Route path="/lk/subcategory/:url" element={<Subcategory />} />
                <Route path="/lk/article/:id" element={<Article />} />
                <Route path="/lk/product/:id" element={<Product />} />
                <Route path="/lk/banners/edit/:id" element={<EditBanners />} />
                <Route path="/lk/banners/edit" element={<EditBanners />} />
                <Route path="/lk/banners" element={<FarmBanners />} />
                <Route
                  path="/lk/articles/edit/:id"
                  element={<EditArticles />}
                />
                <Route path="/lk/articles/edit" element={<EditArticles />} />
                <Route path="/lk/articles" element={<FarmArticles />} />
                <Route
                  path="/lk/products/edit/:id"
                  element={<EditProducts />}
                />
                <Route path="/lk/products/edit" element={<EditProducts />} />
                <Route path="/lk/products" element={<FarmProducts />} />

                <Route path="/room/:link" element={<RoomNavigate />} />

                <Route path="/write/review/:link" element={<WriteReview />} />

                <Route
                  path="/write/recommendation/:link"
                  element={<WriteRecommendation />}
                />
              </Route>

              <Route exact path="/politics" element={<Politics />} />
              <Route exact path="/agreement" element={<Agreement />} />

              <Route path="*" element={<NoMatch />} />
            </Routes>
            <Footer />
          </Suspense>
        </AuthProvider>
      </div>
      <ScrollToTop />
    </Router>
  );
};

export default App;
